import React from "react"
import { graphql, StaticQuery } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import MainPostCard from "../components/mainPostCard"

// import "../utils/global.scss"
import "../utils/normalize.css"
import "../utils/css/screen.css"

//TODO: switch to staticQuery, get rid of comments, remove unnecessary components, export as draft template
const BlogIndex = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title
  const sections = data.allMarkdownRemark.edges
  let sectionsCounter = 0
  return (
    <Layout title={siteTitle}>
      <SEO title="Photography" />
      <div className="main-post-feed">
        {sections.map(({ node }) => {
          sectionsCounter++
          return (
            <MainPostCard
              key={node.fields.slug}
              count={sectionsCounter}
              node={node}
              postClass={`post`}
            />
          )
        })}
        <article className="main-post-card false post with-image pubcover">
          {/* <article className="main-post-card false post with-image" style="background-image: url('/pubcover.jpg';)"> */}
          <a className="main-post-card-link" href="/publications/">
            <div className="main-post-card-content">
              <h2 className="main-post-card-title">Publications</h2>
            </div>
          </a>
        </article>
      </div>
    </Layout>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            thumbnail {
              childImageSharp {
                fluid(maxWidth: 1360) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <BlogIndex location={props.location} props data={data} {...props} />
    )}
  />
)
